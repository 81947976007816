/* eslint-disable @typescript-eslint/no-var-requires */
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { JssProvider } from 'react-jss';
import { Provider } from 'react-redux';
import store from './store/appStore';
import ThemeProvider from './theming/ThemeProvider';

const launchApp = (Component: any) => {
    ReactDOM.render(
        <React.StrictMode>
            <Provider store={store}>
                <JssProvider>
                    <ThemeProvider>
                        <Component />
                    </ThemeProvider>
                </JssProvider>
            </Provider>
        </React.StrictMode>,
        document.getElementById('root'),
    );
};

const launchMain = () => launchApp(App);
launchMain();

// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker.register('/sw.js');
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
if (module.hot && process?.env?.NODE_ENV !== 'production') {
    // module.hot.accept();
    module.hot.accept('./App', () => {
        /* eslint-disable-next-line global-require */
        const NextApp = require('./App').default;
        launchApp(NextApp);
    });
}
