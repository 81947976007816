import { compose, applyMiddleware } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from '../reducers/redux-store';
import thunk from 'redux-thunk';

const configure = (preloadedState: any) => configureStore({
    preloadedState,
    reducer: rootReducer,
    enhancers: [compose(
        applyMiddleware(thunk),
    )],
    middleware: (getDefaultMiddleware: (arg0: { serializableCheck: boolean; }) => any) => getDefaultMiddleware({
        serializableCheck: false,
    }),
});

export default configure;
