import React, { useState } from "react";
import { createSellerProfileFormFields } from "./createProfile.constants";
import RenderFormItem from './generic-object';
import withStyles from "react-jss";
import { connect } from "react-redux";
import GreenTickIcon from "assets/green-tick-icon";
import CrossIcon from "assets/red-cross-icon";
import { Button, Modal } from "antd";
import Loader from "./Loader";
import { NamePath } from "antd/lib/form/interface";

const styles = () => ({
    formContainer: {
        backgroundColor: '#F0F5FF',
        borderRadius: '10px',
        margin: '8px 0',
    },
    documentList: {
        display: 'flex',
        flexDirection: 'column',
        width: '60%',
    },
    docModal: {
        maxHeight: '65vh',
        overflowY: 'auto',
    },
    document: {
        marginTop: '3%',
        display: 'flex',
        alignItems: 'center',
    },
    serialno: {
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        backgroundColor: '#F5F5F5',
        color: '#111111',
        display: 'flex',
        fontWeight: '600',
        justifyContent: 'center',
        alignItems: 'center',
        marginRight: '3%',
    },
    docName: {
        width: '30%',
        display: 'flex',
        fontWeight: '500',
    },
    accept: {
        width: '20%',
        display: 'flex',
        fontWeight: '600',
        alignItems: 'center',
        color: '#008D16',
    },
    reject: {
        width: '20%',
        display: 'flex',
        fontWeight: '600',
        alignItems: 'center',
        color: '#EA2626',
    },
    box: {
        padding: 20,
        alignItems: 'normal',
        boxSizing: 'border-box',
        borderRadius: 4,
        width: '100%',
    },
    validateIcon: {
        color: '#006EC3',
        marginLeft: '10px',
        cursor: 'pointer',
        letterSpacing: 0,
        fontFamily: 'Open Sans',
        fontWeight: 600,
        size: 12,
        marginBottom: 0,
    },
    validate: {
        display: 'flex',
        alignItems: 'center',
    },
});

const {
    embedSignature,
    embedSeal,
    addressType,
} = createSellerProfileFormFields;

const StepThree = (props: any) => {

    const { form, classes, formData, metadata, setFormData } = props;
    const [loading, setLoading] = useState<boolean>(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentDoc, setCurrentDoc] = useState({ name: 'Document', id: 'doc', docLink: '' });
    const { AUTHORIZATION_LETTER_VALUES, AUTHORIZATION_LETTER_PRETTY_NAMES } = metadata;
    const selectedAddressType = form.getFieldValue(addressType.keyPath as NamePath);

    const commonProps = {
        form,
        formData,
        setFormData,
    };

    const handleViewClick = async (doc: any) => {
        setLoading(true);
        setCurrentDoc({ id: doc.id, name: doc.name, docLink: doc.docLink });
        setIsModalVisible(true);
    };

    const handleOk = (e: any) => {
        e.preventDefault();
        e.stopPropagation();
        const authLetters = formData?.authorization_letters?.filter((doc: any) => doc.type !== currentDoc.id) || [];
        setFormData({
            ...formData,
            authorization_letters: [
                ...authLetters,
                {
                    type: currentDoc.id,
                    is_verified: true,
                    timestamp: Date.now(),
                },
            ],
        });
        setIsModalVisible(false);
        setLoading(false);
    };

    const handleCancel = () => {
        const authLetters = formData?.authorization_letters?.filter((doc: any) => doc.type !== currentDoc.id) || [];
        setFormData({
            ...formData,
            authorization_letters: [
                ...authLetters,
                {
                    type: currentDoc.id,
                    is_verified: false,
                    timestamp: Date.now(),
                },
            ],
        });
        setIsModalVisible(false);
        setLoading(false);
    };

    const renderView = (doc: any) => {
        return (
            <div className={classes.validate}>
                <div onClick={() => handleViewClick(doc)}>
                    <h5 className={classes.validateIcon}>VIEW</h5>
                </div>
            </div>
        );
    };

    const renderDocumentsList = () => {
        const AuthorizationLettersList = [...AUTHORIZATION_LETTER_VALUES]?.sort().map((value: string, index: number) => {
            return {
                serialNo: index + 1,
                name: AUTHORIZATION_LETTER_PRETTY_NAMES[value],
                id: value,
                docLink: '',
            };
        });

        const authLettersSelectedMap = formData?.authorization_letters?.reduce((acc: any, doc: any) => {
            acc[doc.type] = doc.is_verified;
            return acc;
        }, {});

        return (
            <div className={classes.documentList}>
                {AuthorizationLettersList.map((doc: any) => {
                    return (
                        <div className={classes.document}>
                            <div className={classes.serialno}>
                                {doc.serialNo}
                            </div>
                            <div className={classes.docName}>{doc.name}</div>
                            <div className={classes.docName}>
                                {renderView(doc)}
                            </div>
                            {authLettersSelectedMap?.[doc.id] === true
                                && (
                                    <div className={classes.accept}>
                                        <GreenTickIcon style={{ backgroundColor: '#E6F8E9', borderRadius: 10 }} />
                                        ACCEPTED
                                    </div>
                                )}
                            {authLettersSelectedMap?.[doc.id] === false
                                && (
                                    <div className={classes.reject}>
                                        <CrossIcon style={{ backgroundColor: '#FFE9E9', borderRadius: 10 }} />
                                        REJECTED
                                    </div>
                                )}
                        </div>
                    );
                })}
            </div>
        );
    };

    const renderFooter = () => {
        const selectedLetter = formData?.authorization_letters?.find((letter: any) => letter.type === currentDoc.id) || {};
        if (selectedLetter?.is_verified) {
            return null;
        }
        return (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end' }}>
                <Button
                    style={{
                        fontSize: 16,
                        margin: '4px',
                        cursor: 'pointer',
                        borderRadius: '4px',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    onClick={handleCancel}
                >
                    Reject
                </Button>
                <Button
                    style={{
                        fontSize: 16,
                        margin: '4px',
                        cursor: 'pointer',
                        borderRadius: '4px',
                        display: 'flex',
                        alignItems: 'center',
                    }}
                    onClick={handleOk}
                >
                    Accept
                </Button>
            </div>
        );
    };

    const handleClose = () => {
        setIsModalVisible(false);
        setLoading(false);
    };

    const renderAuthorisationDocument = () => {
        return (
            <Modal
                key={currentDoc?.id}
                title={currentDoc?.name}
                visible={isModalVisible}
                onOk={handleOk}
                onCancel={handleClose}
                width={550}
                footer={renderFooter()}
            >
                <div className={classes.docModal}>
                    {loading && <Loader zIndex={10} />}
                    <iframe
                        title="template"
                        // eslint-disable-next-line max-len
                        src={`https://seller-profile-management-demo.democonsignmentlabelsandbox2.shipsy.io/public/getDocumentForSeller?template=${currentDoc.id}`}
                        style={{
                            width: '100%', minHeight: '65vh',
                        }}
                        onLoad={() => setLoading(false)}
                    />
                </div>
            </Modal>
        );
    };



    const renderSealAndSignatureDetails = () => {
        const embedSealField = {
            ...embedSeal,
            isRequired: selectedAddressType === 'business',
            rules: [
                {
                    required: selectedAddressType === 'business',
                    message: 'Required',
                },
            ],
        };
        return (
            <div className={classes.boxFieldsCol}>
                <div className={classes.boxField1}>
                    <RenderFormItem
                        field={embedSignature}
                        {...commonProps}
                    />
                </div>
                <div className={classes.boxField1}>
                    <RenderFormItem
                        field={embedSealField}
                        {...commonProps}
                    />
                </div>
            </div>
        );
    };

    const renderSealAndSignature = () => {
        return (
            <div className={classes.box}>
                {renderSealAndSignatureDetails()}
            </div>
        );
    };

    const renderAuthorisationLetters = () => {
        return (
            <div className={classes.box}>
                {renderDocumentsList()}
            </div>
        );
    };

    return <div className={classes.formContainer}>
        {loading && <Loader zIndex={10} />}
        {renderSealAndSignature()}
        <div style={{ backgroundColor: 'white', height: '30px', fontWeight: 700, display: 'flex', alignItems: 'end' }}>Authorization Letters</div>
        {renderAuthorisationLetters()}
        {isModalVisible ? renderAuthorisationDocument() : null}
    </div>
}

const mapStateToProps = (state: any) => {
    const { masterdataReducer } = state;
    return {
        metadata: masterdataReducer.metadata,
    };
};

const StepThreePage = withStyles(styles, { injectTheme: true })(
    (StepThree) as React.ComponentType<any>,
);
export default connect(mapStateToProps, null)(StepThreePage);