import React, { useEffect, useRef } from "react";
import { createSellerProfileFormFields } from "./createProfile.constants";
import RenderFormItem from './generic-object';
import withStyles from "react-jss";
import { Form, message } from "antd";
import { NamePath } from "antd/lib/form/interface";
import { connect } from "react-redux";
import { getCityState } from "api/createProfile";
import { useDebounce } from "hooks/use-debounce";

const styles = () => ({
    formContainer: {
        backgroundColor: '#F0F5FF',
        borderRadius: '10px',
        margin: '8px 0',
    }
});


const {
    senderType,
    addressType,
    senderName,
    senderCompanyName,
    senderAddressLine1,
    senderAddressLine2,
    senderPincode,
    senderCity,
    senderCountry,
    senderMobileNumber,
    senderAlternateMobileNumber,
    senderEmail,
    senderState,
    addressProofBack,
    addressProofFront,
    addressProofType,
    csbvApplicable,
} = createSellerProfileFormFields;

const StepOne = (props: any) => {

    const { form, classes, metadata, formData, setFormData, phone, email } = props;
    const { DOCUMENT_LABEL_MAP, SELLER_TYPE_DOCUMENTS_UPLOAD_MAP, SELLER_TYPE } = metadata;
    const formPincode = Form.useWatch(senderPincode.keyPath as NamePath, form);
    const formSenderType = Form.useWatch(senderType.key, form);
    const formAddressProofType = Form.useWatch(addressProofType.keyPath as NamePath, form);
    const requiredAddressType = Form.useWatch(addressType.keyPath as NamePath, form);
    const formCompanyName = Form.useWatch(senderCompanyName.key as NamePath, form);
    const debounceTimePincodeString = useDebounce(formPincode);

    const prevRequiredAddressType = useRef(null);
    const emailRegex = /\S+@\S+\.\S+/;
    const commonProps = {
        formData,
        form,
        setFormData,
    }
    useEffect(() => {
        if (formPincode?.length === 6) {
            saveCityState();
        }
    }, [debounceTimePincodeString]);

    useEffect(() => {
        if (prevRequiredAddressType.current && prevRequiredAddressType.current !== requiredAddressType) {
            form.resetFields([senderName?.key as NamePath, senderCompanyName?.key as NamePath]);
        }
        prevRequiredAddressType.current = requiredAddressType;
    }, [requiredAddressType])

    const saveCityState = async () => {
        const pincodeValue = form.getFieldValue(senderPincode.keyPath as NamePath);
        try {
            const response = await getCityState(pincodeValue);
            if (response.isSuccess) {
                form.resetFields([senderCity?.keyPath as NamePath, senderState?.keyPath as NamePath]);
                form.setFields([
                    {
                        name: senderCity?.keyPath as NamePath,
                        value: response.data.city || '',
                    },
                    {
                        name: senderState?.keyPath as NamePath,
                        value: response.data.state || '',
                    },
                ]);
            } else {
                message.error(response?.errorMessage || 'Failed to fetch city and state');
            }
        } catch (e) {
            message.error('Failed to fetch city and state');
        }
    };

    const createOptions = (document_type_options: Array<string>) => {
        return document_type_options?.map((option: any) => {
            return {
                value: option,
                label: DOCUMENT_LABEL_MAP[option],
            };
        });
    };

    const renderAddress = () => {
        const senderTypeField = {
            ...senderType,
            options: Object.entries(SELLER_TYPE || {}).map(([, value]) => {
                return ({
                    key: value as string,
                    value: value as string,
                    label: metadata?.SELLER_TYPE_LABEL?.[value as string],
                });
            })
        };
        const senderTypeAddressList = SELLER_TYPE_DOCUMENTS_UPLOAD_MAP?.[formSenderType];
        const requiredAddressProofType = {
            ...addressProofType,
            disabled: !formSenderType,
            tooltipMessage: 'Please select a consignor type first to enable address options.',
            options: createOptions(senderTypeAddressList?.address_proof_options),
        };
        if (formSenderType && !senderTypeAddressList?.address_proof_options?.find((elem: any) => elem === formAddressProofType)) {
            form.setFields([
                {
                    name: addressProofType.keyPath as NamePath,
                    value: undefined,
                },
                {
                    name: ['address_proof', 0, 'front_image'] as NamePath,
                    value: undefined,
                },
                {
                    name: ['address_proof', 1, 'back_image'] as NamePath,
                    value: undefined,
                },
            ]);
        }
        form.setFields([
            {
                name: addressType.keyPath as NamePath,
                value: formSenderType && formSenderType !== 'individual'
                    && formSenderType !== 'foreign_nationals_residing_in_india' ? 'business' : 'individual',
            },
        ]);
        const addressProofFrontImage = {
            ...addressProofFront,
            disabled: !formAddressProofType,
            key: ['address_proof', 0, 'front_image'],
            imageExt: ['location_details', 'front_image_details', 'ext'],
        };
        const addressProofBackImage = {
            ...addressProofBack,
            disabled: !formAddressProofType,
            key: ['address_proof', 1, 'back_image'],
            imageExt: ['location_details', 'back_image_details', 'ext'],
        };
        const documentsRequired = metadata.DOCUMENT_TYPE_REQUIRED_FIELDS_MAP[formAddressProofType] || ['front_image'];
        const showAddressFrontImage = formSenderType && formAddressProofType ? documentsRequired.indexOf('front_image') > -1 || false : false;
        const showAddressBackImage = formSenderType && formAddressProofType ? documentsRequired.indexOf('back_image') > -1 || false : false;
        const senderNameField = {
            ...senderName,
            disabled: requiredAddressType === 'business',
        };
        if (requiredAddressType === 'business') {
            form.setFields([
                {
                    name: senderName.key,
                    value: formCompanyName,
                },
            ]);
        }
        const requiredSenderCompanyName = {
            ...senderCompanyName,
            rules: [
                {
                    required: true,
                    message: 'Required',
                },
                {
                    max: 50,
                    message: 'Maximum 50 characters',
                },
            ],
            isRequired: true,
        };
        if (!requiredSenderCompanyName.isRequired && !form.getFieldValue(senderCompanyName.key)) {
            form.setFields([
                {
                    name: senderCompanyName.key,
                    errors: [],
                },
            ]);
        }
        var requiredSenderEmail = {
            ...senderEmail,
            rules: [
                {
                    pattern: emailRegex,
                    message: 'Invalid email',
                },
                {
                    required: true,
                    message: 'Required',
                },
            ],
        };
        var requiredSenderMobileNumber = {
            ...senderMobileNumber,
        }
        if (email) {
            requiredSenderEmail = {
                ...requiredSenderEmail,
                disabled: true,
            }
            form.setFields([{
                name: requiredSenderEmail?.key as NamePath,
                value: email,
            }])
        }
        if (phone) {
            requiredSenderMobileNumber = {
                ...requiredSenderMobileNumber,
                disabled: true,
            }
            form.setFields([{
                name: requiredSenderMobileNumber?.key as NamePath,
                value: phone,
            }])
        }
        return <>
            <RenderFormItem
                field={senderTypeField}
                {...commonProps}
            />
            <RenderFormItem
                field={csbvApplicable}
                {...commonProps}
            />
            <RenderFormItem
                {...commonProps}
                field={addressType}
            />
            <RenderFormItem
                {...commonProps}
                field={senderNameField}
            />
            {requiredAddressType === 'business' ? (
                <RenderFormItem
                    {...commonProps}
                    field={requiredSenderCompanyName}
                />
            ) : null}
            <RenderFormItem
                {...commonProps}
                field={senderAddressLine1}
            />
            <RenderFormItem
                {...commonProps}
                field={senderAddressLine2}
            />
            <RenderFormItem
                {...commonProps}
                field={senderPincode}
            />
            <RenderFormItem
                {...commonProps}
                field={senderCity}
            />
            <RenderFormItem
                {...commonProps}
                field={senderState}
            />
            <RenderFormItem
                {...commonProps}
                field={senderCountry}
            />
            <RenderFormItem
                field={requiredSenderMobileNumber}
                {...commonProps}
            />
            <RenderFormItem
                field={senderAlternateMobileNumber}
                {...commonProps}
            />
            <RenderFormItem
                field={requiredSenderEmail}
                {...commonProps}
            />
            <RenderFormItem
                field={requiredAddressProofType}
                {...commonProps}
            />
            {showAddressFrontImage && <RenderFormItem
                field={addressProofFrontImage}
                {...commonProps}
            />}
            {showAddressBackImage && (
                <RenderFormItem
                    field={addressProofBackImage}
                    {...commonProps}
                />
            )}</>
    }

    return <div className={classes.formContainer}>
        {renderAddress()}
    </div>
}

const mapStateToProps = (state: any) => {
    const { masterdataReducer } = state;
    return {
        metadata: masterdataReducer.metadata,
        phone: masterdataReducer.phone,
        email: masterdataReducer.email,
    };
};

const StepOnePage = withStyles(styles, { injectTheme: true })(
    (StepOne) as React.ComponentType<any>,
);
export default connect(mapStateToProps, null)(StepOnePage);