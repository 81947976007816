import withStyles from 'react-jss';
import { FrownOutlined } from '@ant-design/icons';

const styles = {
    main: {
        width: '100%',
        height: '100%',
        overflow: 'hidden',
    },
    message1: {
        textAlign: 'center',
        color: '#007468',
        fontSize: '1.0rem',
        fontFamily: 'Roboto, sans-serif',
    },
    message2: {
        marginTop: '2%',
        textAlign: 'center',
        color: '#4a4a4a',
        fontSize: '1.0rem',
        fontFamily: 'Roboto, sans-serif',
    },
};

const ErrorPage = (props: any) => {
    const { classes, message } = props;
    return (
        <div className={classes.main}>
            <div style={{ width: '100%', textAlign: 'center', marginTop: '20%', fontSize: '100px' }}>
                <FrownOutlined />
            </div>
            <div className={classes.message1}>SORRY !</div>
            <div className={classes.message2}>
                {message}
            </div>
        </div>
    );
};

const NotFound404 = withStyles(styles)(ErrorPage);
export default NotFound404;
