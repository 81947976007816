import lodash from 'lodash';

export const getFileExtension = (filename: string) => {
    const ext = /^.+\.([^.]+)$/.exec(filename);
    return ext == null ? '' : ext[1];
}

export const snakeToPretty = (inp: string) => {
    const pretty = lodash.startCase(inp);
    return pretty;
};