import { DELETE, GET, POST, PUT } from "./apiHandler";
const config = require('../config').default;
import { DELETE_IMAGE, FETCH_MASTERDATA, FETCH_USER_DATA, GET_CITY_STATE, GET_DOWNLOAD_IMAGE_URL, GET_UPLOAD_IMAGE_URL, VALIDATE_KYC, CREATE_USER } from "./routes";
import store from "store/appStore";

const SELLER_PROFILE_API_URL = config.SELLER_PROFILE_API_URL;
export const fetchUserData = async () => {
    const AUTH_URL = store?.getState().masterdataReducer.auth;
    return GET(`${SELLER_PROFILE_API_URL}${FETCH_USER_DATA}${AUTH_URL}`);
};

export const fetchMasterData = async () => {
    const AUTH_URL = store?.getState().masterdataReducer.auth;
    return GET(`${SELLER_PROFILE_API_URL}${FETCH_MASTERDATA}${AUTH_URL}`);
};

export const getImageUploadUrls = async (params: any) => {
    const AUTH_URL = store?.getState().masterdataReducer.auth;
    return POST(`${SELLER_PROFILE_API_URL}${GET_UPLOAD_IMAGE_URL}${AUTH_URL}`, {...params});
};

export const deleteImage = async (params: any) => {
    const AUTH_URL = store?.getState().masterdataReducer.auth;
    return POST(`${SELLER_PROFILE_API_URL}${DELETE_IMAGE}${AUTH_URL}`, {...params});
};

export const downloadImage = async (params: any) => {
    const AUTH_URL = store?.getState().masterdataReducer.auth;
    return POST(`${SELLER_PROFILE_API_URL}${GET_DOWNLOAD_IMAGE_URL}${AUTH_URL}`, {...params});
};

export const uploadImage = async (file: any, url: string, type: string) => {
    return PUT(url, file, type);
};

export const callDeleteImage = async (url: any) => {
    return DELETE(url);
};

export const validateIndividualKyc = async (params: any) => {
    const AUTH_URL = store?.getState().masterdataReducer.auth;
    return POST(`${SELLER_PROFILE_API_URL}${VALIDATE_KYC}${AUTH_URL}`, params);
};

export const getCityState = async (pincode: any) => {
    const AUTH_URL = store?.getState().masterdataReducer.auth;
    return POST(`${SELLER_PROFILE_API_URL}${GET_CITY_STATE}${AUTH_URL}?pincode=${pincode}`,{});
};

export const createSeller = async (params: any) => {
    const AUTH_URL = store?.getState().masterdataReducer.auth;
    return POST(`${SELLER_PROFILE_API_URL}${CREATE_USER}${AUTH_URL}`, params);
};