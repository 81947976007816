
import * as React from 'react';
import {
    Form, Input, Select, Switch, Radio, Upload, message, FormInstance, Button, Image, Checkbox, DatePicker, Tooltip,
    Modal,
} from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import {
    UploadOutlined,
    EyeOutlined,
    DeleteOutlined,
} from '@ant-design/icons';
import { FormField, InputTypes } from 'components/CreateProfile.types';
import { StylesProps } from 'theming/jssTypes';
import TextArea from 'antd/lib/input/TextArea';
import { getFileExtension } from 'library/Helper';
import { UploadFile } from 'antd/lib/upload/interface';
import {
    getImageUploadUrls, uploadImage, downloadImage, deleteImage, callDeleteImage,
} from 'api/createProfile';
import Loader from 'components/Loader';
import withStyles from 'react-jss';
import { connect } from 'react-redux';
import moment from 'moment';

const styles = () => ({
    switch: {
        marginRight: '10px',
    },
    formitem: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        fontSize: '12px',
        fontFamily: 'Open Sans',
        fontWeight: 400,
        color: '#333',
        display: 'flex',
        padding: '3px 5px',
    },
    formitemSelect: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        fontSize: '12px',
        fontFamily: 'Open Sans',
        fontWeight: 400,
        color: '#333',
        display: 'flex',
        padding: '3px 5px',
        '&> span': {
            width: '100%',
        },
    },
    boxField1: {
        display: 'flex',
        flexDirection: 'column',
    },
    label: {
        marginBottom: '2px',
        display: 'flex',
        alignItems: 'center',
    },
    inputform: {
        width: '100%',
        height: '40px',
    },
    switchform: {
        height: '0px',
    },
    switchlabel: {
        fontSize: '12px',
        fontWeight: 600,
        marginTop: '8px',
    },
    title: {
        fontSize: '12px',
        fontFamily: 'Open Sans',
        fontWeight: 400,
        color: '#333',
        display: 'flex',
    },
    image: {
        display: 'flex',
        marginLeft: '16px',
    },
    imageContainer: {
        display: 'flex',
        minWidth: '32px',
    },
    customTooltip: {
        '& .ant-tooltip-inner': {
            backgroundColor: '#FCFCFC',
            color: '#909090',
        },
    },
    iframeModal: {
        width: 630,
        height: 500,
        '& .ant-modal-header':{
            borderBottom: 'none',
        },
    },
});

interface FormItemProps extends StylesProps<ReturnType<typeof styles>> {
    field: FormField;
    formData: any;
    form: FormInstance;
    optionList: any;
    seller_id: any;
}
const RenderFormItems = (props: FormItemProps) => {
    const {
        field,
        form,
        formData,
        classes,
        optionList,
        seller_id,
    } = props;
    const fieldValue = form.getFieldValue(field.key);
    const fileExtValue = field.imageExt ? form.getFieldValue(field.imageExt) : '';

    const [uploadingFile, setUploadingFile] = React.useState<boolean>(false);
    const [fileList, setFileList] = React.useState<UploadFile[]>(fieldValue ? [{
        uid: (+new Date()).toString(),
        name: Array.isArray(field?.key) ? 'file' : field.key,
        status: 'done',
        url: fieldValue,
        type: fileExtValue === 'pdf' ? 'pdf' : 'image',
    }] : []);
    const [isImageVisible, setIsImageVisible] = React.useState(false);
    const [imagePreviewLoading, setImagePreviewLoading] = React.useState(false);
    const [isPdfVisible, setIsPdfVisible] = React.useState(false);

    React.useEffect(() => {
        if (fieldValue) {
            setFileList([{
                uid: (+new Date()).toString(),
                name: Array.isArray(field?.key) ? 'file' : field.key,
                status: 'done',
                url: fieldValue,
                type: fileExtValue === 'pdf' ? 'pdf' : 'image',
            }]);
        } else {
            setFileList([]);
        }
    }, [fieldValue, field]);

    const createBody = (requiredField: FormField) => {
        const body: {
            document_type?: string;
            document_number?: string;
            seller_id?: string;
            type?: string;
            name?: string;
        } = {};
        const addressType = form.getFieldValue(['location_details', 'address_proof_type'] as NamePath);
        body.seller_id = seller_id;
        if (Array.isArray(requiredField.key)) {
            const fieldName = requiredField.key[0];
            const index = requiredField.key[1];
            const imageType = requiredField.key[2];
            const documentDetails = form.getFieldValue(fieldName);
            body.document_type = documentDetails?.[index]?.document_type;
            body.document_number = documentDetails?.[index]?.document_number;
            body.type = imageType;
            body.name = fieldName === 'kyc_documents' ? 'kyc' : fieldName;
            if (fieldName === 'address_proof') {
                body.document_type = addressType;
            }
        } else {
            body.name = requiredField?.key;
            body.document_type = requiredField?.key;
            if (requiredField?.key === 'ad_code_certificate') {
                body.name = 'bank_account';
            }
        }
        return body;
    };

    const handleRemoveFile = async () => {
        setUploadingFile(true);
        const body = createBody(field);
        try {
            const response = await deleteImage(body);
            if (response?.isSuccess) {
                setFileList([]);
                if (Array.isArray(field.key) || field.imageExt) {
                    form.setFields(
                        [
                            {
                                name: field.key,
                                value: null,
                            },
                            {
                                name: field.imageExt as NamePath,
                                value: null,
                            },
                        ],
                    );
                } else {
                    form.setFieldsValue({
                        [field.key]: null,
                    });
                }
                try {
                    await callDeleteImage(`${response?.data?.url}`);
                } catch (error: any) {
                    message.error(error?.errorMessage || 'Failed to delete image');
                }
            } else {
                message.error(response?.errorMessage || 'Failed to delete image');
            }
        } catch (error: any) {
            message.error(error?.errorMessage || 'Failed to delete image');
        }
        setImagePreviewLoading(false);
        setUploadingFile(false);
    };

    const handleFileUpload = async (requiredField: FormField, file: any) => {
        const body = createBody(requiredField);
        setUploadingFile(true);
        setIsImageVisible(false);

        const response = await getImageUploadUrls({
            ...body,
        });

        if (response.isSuccess) {
            const upload = await uploadImage(file, response?.data?.url, file?.type);

            if (upload?.isSuccess) {
                const downloadResponse = await downloadImage(body);
                const changedfileList: UploadFile[] = [
                    {
                        uid: file?.id,
                        name: field.key,
                        status: 'done',
                        url: downloadResponse?.data?.url,
                        type: downloadResponse?.data?.image_details?.ext === 'pdf' ? 'pdf' : 'image',
                    },
                ];
                setFileList(changedfileList);
                if (Array.isArray(requiredField.key) || requiredField.imageExt) {
                    form.setFields(
                        [
                            {
                                name: requiredField.key,
                                value: downloadResponse?.data?.url,
                            },
                            {
                                name: requiredField.imageExt as NamePath,
                                value: downloadResponse?.data?.image_details?.ext,
                            },
                        ],
                    );
                } else {
                    form.setFieldsValue({
                        [field.key]: downloadResponse?.data?.url,
                    });
                }
            } else {
                handleRemoveFile();
                message.error(upload.errorMessage || 'Failed to upload image');
            }
        } else {
            setFileList([]);
            if (Array.isArray(field.key)) {
                form.setFields(
                    [
                        {
                            name: field.key,
                            value: null,
                        },
                    ],
                );
            } else {
                form.setFieldsValue({
                    [field.key]: null,
                });
            }
            message.error(response.errorMessage || 'Failed to get image upload url');
        }
        setUploadingFile(false);
    };

    const renderInput = (requiredField: FormField) => (
        <Form.Item
            name={requiredField.keyPath || requiredField.key}
            initialValue={requiredField.defaultValue}
            valuePropName={requiredField.valuePropName}
            rules={requiredField?.rules || [{ required: requiredField.isRequired, message: 'Required' }]}
        >
            <Input
                type={requiredField.type}
                min={0}
                autoComplete="off"
                placeholder={requiredField.placeholder}
                style={{
                    fontSize: '12px', width: 'calc(100% - 8px)',
                    border: '1px solid #ccc', borderRadius: '5px', height: '32px'
                }}
                defaultValue={requiredField.defaultValue?.toString()}
                disabled={requiredField.disabled}
            />
        </Form.Item>
    );

    const renderswitch = (requiredField: FormField) => (
        <Form.Item
            name={requiredField.keyPath || requiredField.key}
            initialValue={requiredField.defaultValue}
            valuePropName="checked"
            getValueFromEvent={(checked) => checked}
            trigger="onChange"
            rules={requiredField?.rules || [{ required: requiredField.isRequired, message: 'Required' }]}
        >
            <Switch
                size="small"
                defaultChecked={false}
                className={classes.switch}
                disabled={requiredField.disabled}
            />
        </Form.Item>
    );

    const renderSelect = (requiredField: FormField) => {
        const options = requiredField.options || optionList;
        return (
            <Form.Item
                name={requiredField.keyPath || requiredField.key}
                initialValue={requiredField.defaultValue}
                valuePropName={requiredField.valuePropName}
                rules={requiredField?.rules || [{ required: requiredField.isRequired, message: 'Required' }]}
            >
                <Select
                    options={options}
                    mode={requiredField.mode}
                    placeholder={requiredField.placeholder}
                    filterSort={
                        (optionA, optionB) => (
                            (optionA?.label ?? '') as string)?.toLowerCase()
                            .localeCompare(((optionB?.label ?? '') as string)?.toLowerCase())
                    }
                    allowClear
                    disabled={requiredField.disabled}
                />
            </Form.Item>
        );
    };

    const renderImage = (file: any) => {
        if (!file || !file.url) {
            return null;
        }
        return (
            <div className={classes.image}>
                <div className={classes.imageContainer}>
                    {isImageVisible && (
                        <>
                            <Image
                                placeholder
                                src={file.url}
                                alt="Unable to load Image"
                                style={{
                                    display: 'none',
                                }}
                                onError={() => {
                                    message.error('Unable to load Image.');
                                    setIsImageVisible(false);
                                    setImagePreviewLoading(false);
                                }}
                                onLoad={() => setImagePreviewLoading(false)}
                                onAbort={() => {
                                    setImagePreviewLoading(false);
                                    setIsImageVisible(false);
                                }}
                                preview={{
                                    visible: isImageVisible && !imagePreviewLoading,
                                    onVisibleChange: (visible) => {
                                        if (!visible) {
                                            setIsImageVisible(false);
                                            setImagePreviewLoading(false);
                                        }
                                    },
                                }}
                            />
                        </>
                    )}
                    {isPdfVisible ? (
                        <Modal
                            title=" "
                            visible={!!file.url}
                            onCancel={() => {
                                setIsPdfVisible(false);
                            }}
                            footer={null}
                            className={classes.iframeModal}
                            width={550}
                        >
                            <iframe
                                width={500}
                                height={450}
                                src={file.url}
                                title="Preview"
                            />
                        </Modal>
                    ) : null}
                    <Button
                        onClick={() => {
                            if (file.type === 'image') {
                                setIsImageVisible(true);
                                setImagePreviewLoading(true);
                            } else {
                                setIsPdfVisible(true);
                            }
                        }}
                    >
                        <EyeOutlined
                            style={{
                                color: '#1677FF',
                                cursor: 'pointer',
                                fontSize: '20px',
                            }}
                        />
                    </Button>
                </div>
                <Button style={{ marginLeft: '5px' }} onClick={handleRemoveFile}>
                    <DeleteOutlined
                        style={{
                            color: 'red',
                            cursor: 'pointer',
                            fontSize: '15px',
                        }}
                    />
                </Button>
            </div>
        );
    };

    const renderUploadBox = (requiredField: FormField) => {
        return (
            <div style={{ position: 'relative' }}>
                {(uploadingFile || imagePreviewLoading) && (
                    <div style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: 10,
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        pointerEvents: 'all',
                    }}
                    >
                        <Loader />
                    </div>
                )}
                <div style={{ display: 'flex' }}>
                    <Form.Item
                        name={requiredField.keyPath || requiredField.key}
                        rules={requiredField?.rules || [{ required: requiredField.isRequired, message: 'Required' }]}
                    >
                        <Upload
                            showUploadList={!requiredField.showPreview}
                            maxCount={1}
                            customRequest={(upload) => handleFileUpload(requiredField, upload?.file)}
                            disabled={uploadingFile || requiredField.disabled}
                            fileList={fileList}
                            beforeUpload={(file) => {
                                const allowedFileTypes = requiredField.key === 'signature_image'
                                || requiredField.key === 'seal_image' ? ['JPG', 'JPEG'] : ['JPG', 'JPEG', 'PDF'];
                                const isLt5M = file.size / 1024 / 1024 < (requiredField.maxFileSize || 20);
                                if (!isLt5M && requiredField.maxFileSize) {
                                    message.error('Image must smaller than 3MB!');
                                    return Upload.LIST_IGNORE;
                                }
                                const extension = getFileExtension(file?.name);
                                if (!allowedFileTypes.includes(extension.toUpperCase())) {
                                    message.error('Unsupported file type!');
                                    return Upload.LIST_IGNORE;
                                }
                                return true;
                            }}
                        >
                            <Button
                                style={{
                                    borderRadius: 4,
                                    fontSize: 12,
                                }}
                                disabled={uploadingFile || requiredField.disabled}
                            >
                                <UploadOutlined />
                                <span style={{ fontSize: 12, marginLeft: 6 }}> Upload </span>
                            </Button>
                        </Upload>
                    </Form.Item>
                    {requiredField.showPreview && fileList.map((file) => renderImage(file))}
                </div>
            </div>
        );
    };

    const renderTextArea = (requiredField: FormField) => (
        <Form.Item
            name={requiredField.keyPath || requiredField.key}
            initialValue={requiredField.defaultValue}
            valuePropName={requiredField.valuePropName}
            rules={requiredField?.rules || [{ required: requiredField.isRequired, message: 'Required' }]}
        >
            <TextArea
                placeholder={requiredField.placeholder}
                value={requiredField.defaultValue?.toString()}
            />
        </Form.Item>
    );

    const rendercheckbox = (requiredField: FormField) => (
        <Form.Item
            name={requiredField.keyPath || requiredField.key}
            initialValue={requiredField.defaultValue}
            valuePropName="checked"
            getValueFromEvent={(event) => event.target.checked}
            trigger="onChange"
            rules={requiredField?.rules || [{ required: requiredField.isRequired, message: 'Required' }]}
        >
            <Checkbox
                defaultChecked={requiredField.defaultValue === true}
                disabled={requiredField.disabled}
            />
        </Form.Item>
    );

    const renderRadio = (requiredField: FormField) => (
        <Form.Item
            name={requiredField.keyPath || requiredField.key}
            initialValue={requiredField.defaultValue}
            valuePropName={requiredField.valuePropName}
            rules={requiredField?.rules || [{ required: requiredField.isRequired, message: 'Required' }]}
        >
            <Radio.Group
                disabled={requiredField.disabled}
                style={{ display: 'flex' }}
            >
                {requiredField.options && requiredField.options.map((option) => (
                    <Radio key={option.value} value={option.value}>{option.label}</Radio>
                ))}
            </Radio.Group>
        </Form.Item>
    );

    const renderDateInput = (requiredField: FormField) => (
        <Form.Item
            name={requiredField.keyPath || requiredField.key}
            initialValue={requiredField.defaultValue}
            rules={requiredField?.rules || [{ required: requiredField.isRequired, message: 'Required' }]}
        >
            <DatePicker
                style={{ fontSize: '12px' }}
                placeholder={requiredField.placeholder}
                disabled={requiredField.disabled}
                disabledDate={(date) => (date && moment().isBefore(date, 'day'))}
            />
        </Form.Item>
    );

    if (!field) {
        return (
            <div className={classes.formitem} />
        );
    }

    switch (field.type) {
        case InputTypes.Input:
            return (
                <div className={classes.formitem} style={field.css ? { ...field.css } : {}}>
                    <div className={classes.label}>
                        {field.label}
                        {(field.isRequired) ? '*' : ''}
                    </div>
                    <div className={classes.inputform}>
                        {renderInput(field)}
                    </div>
                </div>
            );
        case InputTypes.Switch:
            return (
                <div className={classes.formitem} style={field.css ? { ...field.css } : {}}>
                    <div className={classes.label}>
                        {field.label}
                    </div>
                    <div className={classes.inputform}>
                        {renderswitch(field)}
                    </div>
                </div>
            );
        case InputTypes.Select:
            return (
                <div className={classes.formitemSelect} style={field.css ? { ...field.css } : {}}>
                    <Tooltip
                        title={field?.disabled ? field?.tooltipMessage || '' : ''}
                        overlayClassName={classes.customTooltip}
                    >
                        <div className={classes.label}>
                            {field.label}
                            {(field.isRequired) ? '*' : ''}
                        </div>
                        <div className={classes.inputform}>
                            {renderSelect(field)}
                        </div>
                    </Tooltip>
                </div>
            );
        case InputTypes.Textarea:
            return (
                <div className={classes.formitem} style={field.css ? { ...field.css } : {}}>
                    <div className={classes.label}>
                        {field.label}
                        {(field.isRequired) ? '*' : ''}
                    </div>
                    <div className={classes.inputform}>
                        {renderTextArea(field)}
                    </div>
                </div>
            );
        case InputTypes.Checkbox:
            return (
                <div className={classes.formitem} style={field.css ? { ...field.css } : {}}>
                    <div className={classes.label}>
                        {field.label}
                        {(field.isRequired) ? '*' : ''}
                    </div>
                    <div className={classes.inputform}>
                        {rendercheckbox(field)}
                    </div>
                </div>
            );
        case InputTypes.Date:
            return (
                <div className={classes.formitem} style={field.css ? { ...field.css } : {}}>
                    <div className={classes.label}>
                        {field.label}
                        {(field.isRequired) ? '*' : ''}
                    </div>
                    <div className={classes.inputform}>
                        {renderDateInput(field)}
                    </div>
                </div>
            );
        case InputTypes.Radio:
            return (
                <div className={classes.formitem} style={field.css ? { ...field.css } : {}}>
                    <div className={classes.label}>
                        {field.label}
                        {(field.isRequired) ? '*' : ''}
                    </div>
                    <div className={classes.inputform}>
                        {renderRadio(field)}
                    </div>
                </div>
            );

        case InputTypes.Upload:
            return (
                <div className={classes.formitem} style={field.css ? { ...field.css } : {}}>
                    <div>
                        <div className={classes.boxField1}>
                            {field.label}
                            {(field.isRequired) ? '*' : ''}
                        </div>
                        <div className={classes.inputform}>
                            {renderUploadBox(field)}
                        </div>
                    </div>
                    {
                        field.smallMessage1 ? (
                            <small style={{ display: 'block', marginTop: 1, width: '33%' }}>{field.smallMessage1}</small>
                        ) : null
                    }
                    {
                        field.smallMessage2 ? (
                            <small style={{ display: 'block', marginTop: 1 }}>{field.smallMessage2}</small>
                        ) : null
                    }
                </div>
            );

        default:
            return (<h1>N/A</h1>);
    }
};

const mapStateToProps = (state: any) => {
    const { masterdataReducer } = state;
    return {
        seller_id: masterdataReducer.seller_id,
    };
};

const RenderFormItem = withStyles(styles, { injectTheme: true })(
    (RenderFormItems) as React.ComponentType<any>,
);
export default connect(mapStateToProps)(RenderFormItem);
