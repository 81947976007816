import { Dispatch } from "redux";
import { SET_CREATE_SUCCESS, SET_META_DATA, SET_USER_AUTH, SET_USER_DATA } from "./constants";

export const setUserAuth = (data: any) => (dispatch: Dispatch) => {
    dispatch({
        data,
        type: SET_USER_AUTH,
    });
};

export const setUserData = (data: any) => (dispatch: Dispatch) => {
    dispatch({
        data,
        type: SET_USER_DATA,
    });
};

export const setMetaData = (data: any) => (dispatch: Dispatch) => {
    dispatch({
        data,
        type: SET_META_DATA,
    });
};

export const setCreateSuccess = (data: any) => (dispatch: Dispatch) => {
    dispatch({
        data,
        type: SET_CREATE_SUCCESS,
    });
};